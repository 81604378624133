<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>Ürünler</CCardHeader>
                <CCardBody>
                    <CDataTable
                        hover
                        striped
                        :items="list"
                        :fields="fields"
                        clickable-rows
                        @row-clicked="redirectToDetail"
                    />
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import axios from 'axios';


export default {
    name: 'MailTemplateList',

    data () {
        return {
            list: [],
            fields: [
                {
                    key: 'name',
                    label: 'Isim',
                    _classes: 'font-weight-bold'
                }
            ],
            page: 1,
            limit: 100,
            totalCount: 0
        }
    },

    methods: {
        redirectToDetail(item) {
            this.$router.push({ path: `${item._id}` });
        },

        async loadList() {
            const response = await axios({
                method: 'get',
                url: '/mail/templates',
            });
            this.list = response.data.results;
        },
    },

    async mounted() {
        this.loadList();
    }
}
</script>
